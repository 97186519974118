@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
  background-color: #321; /* Background color of the container */
  background-image: url("../img/parallaxPhotos/Natasha&Adam-ChelseaShoesmithPhotography-58.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /*border-radius: 25px; /* Border radius of the container*/
  /*--box-shadow ;	/* Bow shadow of the container*/
  /*--container-align ;	/* `align-items` property of a flex container*/
  /*--container-display ;	/* Display option of the container. Defaults to flex.*/
  /*--container-height ;	/* Height of the container. Defaults to 100vh.*/
  /*--container-justify ;	/* `justify-content` property of a flex container*/
  /*--margin-bottom ;	/* Margin below the component*/
  /*--min-width ;	/* Minimum width of the container*/
  /*--padding ;	/* Padding within the component*/
  /*--width ;	/* Width of the container*/
}

amplify-sign-in {
  --footer-color: #2f0211; /*Font color of the footer
    --footer-font-family	Font family of the footer
    --footer-font-size	Font size of the footer
    --footer-weight*/
  /*background-color: blue;*/
}

:root {
  --amplify-primary-color: #2f0211;
  --amplify-primary-tint: #2f0211;
  --amplify-primary-shade: #2f0211;
  --amplify-font-family: "Montserrat";
}

.amplify-tabs {
  display: none;
}

/*.amplify-button {
  background: var(--amplify-primary-color);
}

.amplify-button:hover {
  background: gray;
}*/

.amplify-flex-back {
  background-color: #321; /* Background color of the container */
  background-image: url("../img/parallaxPhotos/Natasha&Adam-ChelseaShoesmithPhotography-58.jpg");
  background-size: cover;
  height: 100vh;
}
.amplify-button[data-variation="primary"] {
  background-color: var(--amplify-primary-color);
}

.amplify-button[data-variation="link"] {
  color: #2f0211;
}

.amplify-button[data-variation="link"]:hover {
  background-color: grey;
  color: white;
}

.amplify-button[data-variation="primary"]:hover {
  background-color: grey;
}

.amplify-button:hover {
  background-color: grey;
  color: white;
  border-color: #2f0211;
}

.amplify-button:focus {
  background-color: white;
  color: #2f0211;
  border-color: #2f0211;
  box-shadow: 0px 2px 4px var(--amplify-primary-color);
}

.amplify-input:focus {
  border-color: var(--amplify-primary-color);
  box-shadow: 0px 2px 4px var(--amplify-primary-color);
}
